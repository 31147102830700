<script>
export default {
  data(){
    return {
      message: "",
      type: "info",
    }
  },
  methods:{
    show(message, type, params = {}){
      var options = Object.assign({
        timeout: 5000,
      }, params)
      this.message = message
      this.type = type
      $(this.$el).fadeIn(300)
      $("html,body").animate({scrollTop:$(this.$el).offset().top-100}, 300)
      if(options.timeout) setTimeout(()=>{ $(this.$el).hide() }, options.timeout)
    }
  }
}
</script>

<template>
  <div :class="'alert alert-'+type" v-html="message" style="display:none"></div>
</template>